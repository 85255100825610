/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Typography from "root/components/Typography";
import { useTranslation } from "react-i18next";
import legalStyles from "../../sections/LegalHero/index.module.css";
import LegalDocument from "../legal-document";

function TermsAndConditions({ launcher }) {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage("pt-PT");
  }, []);

  return (
    <LegalDocument
      title={t("pages.termsConditions.label")}
      bodyTitle={t("pages.termsConditions.title")}
      currentPage="termsConditions"
      launcher={launcher}
    >
      <Typography variant="h2" color="yellow" weight="medium">
        Sioslife – For Younger Spirits (Sioslife S.A.)
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          Estas condições Gerais de Utilização das soluções Sioslife® devem ser
          lidas com atenção antes de utilizar qualquer das soluções Sioslife®. A
          utilização das soluções Sioslife® indica que o Utilizador tem
          conhecimento destas Condições Gerais de Utilização, concordando com as
          mesmas. Estas Condições Gerais de Utilização encontram-se publicadas
          em &quot;
          <a href={launcher ? "#" : "https://sioslife.com"}>
            https://sioslife.com
          </a>
          &quot; , podendo ser consultadas a todo o tempo.
        </Typography>
      </div>
      <br />
      <br />
      <br />
      <Typography variant="h3" color="yellow" weight="medium">
        1. Partes
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          São partes nestas Condições Gerais: A empresa Sioslife S.A. e o
          Utilizador, que acede às plataformas Sioslife®, enquanto utilizador
          das soluções Sioslife®, familiar ou amigo/conhecido de um utilizador
          das soluções Sioslife®, ou o Utilizador que, enquanto profissional de
          uma entidade ou instituição que possui soluções Sioslife®, acede à
          área de colaborador na plataforma online, nas aplicações móveis ou no
          sistema instalado nessa mesma entidade ou instituição.
        </Typography>
      </div>
      <br />
      <br />
      <br />
      <Typography variant="h3" color="yellow" weight="medium">
        2. Objeto
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          As presentes condições regulam a licença de utilização, em caráter não
          exclusivo e não transferível, das soluções Sioslife®. A licença de
          utilização das soluções Sioslife® poderá ser gratuita ou onerosa,
          dependendo do tipo de utilizador, da solução e das condições
          comerciais acordadas e contratualizadas entre as partes.
        </Typography>
      </div>
      <br />
      <br />
      <br />
      <Typography variant="h3" color="yellow" weight="medium">
        3. Definições
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          As soluções Sioslife® são sistemas interativos que resultam da
          combinação de hardware e software ou apenas software, e que poderão
          ser disponibilizadas em ambiente de utilização fixa (desktop) ou
          utilização móvel (tablet ou smartphone). O Software Sioslife® inclui
          diversas funcionalidades destinadas a combater o afastamento social e
          o sedentarismo da população envelhecida. Estas funcionalidades são
          personalizadas de acordo com as características de cada utilizador. Em
          torno de cada utilizador, os seus familiares, amigos e prestadores de
          cuidados, têm a possibilidade de efetuar comunicação, monitorização e
          acompanhamento do dia a dia e das atividades dos seniores, bem como
          funcionalidades de gestão e configuração do sistema.
        </Typography>
      </div>
      <br />
      <br />
      <br />
      <Typography variant="h3" color="yellow" weight="medium">
        4. Descrição e Requisitos de Funcionamento das Soluções Sioslife®
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          O Software Sioslife®, parte integrante das soluções Sioslife® funciona
          nos sistemas operativos Windows, Android e iOS, assim como nos
          navegadores de internet mais comuns. A aplicação permite entrada de
          dados em texto e o upload de ficheiros de imagem e vídeo. Em termos de
          output de dados todos estes são a nível gráfico e visual, sendo
          determinado o aspeto gráfico pela estrutura da aplicação. O software
          não tem necessidades específicas para funcionar, sendo apenas
          necessário um dispositivo com ligação à Internet e com possibilidade
          de inserção de texto. A aplicação garante o funcionamento em Tablets,
          Desktops/Laptops e smartphones. A transmissão de dados na plataforma é
          feita de forma segura, sendo os dados enviados de forma encriptada.
        </Typography>
      </div>
      <br />
      <br />
      <br />
      <Typography variant="h3" color="yellow" weight="medium">
        5. Aquisição das Licenças de Utilização
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          O fornecimento do serviço de subscrição das soluções Sioslife® poderá
          estar sujeita ao prévio pagamento pelo Utilizador de uma licença de
          utilização.
        </Typography>
      </div>
      <br />
      <br />
      <br />
      <Typography variant="h3" color="yellow" weight="medium">
        6. Obrigações da Empresa
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          Todos os dados são guardados numa base de dados encriptada, sendo a
          responsabilidade da Sioslife S.A., armazenar toda a informação
          inserida pelo Utilizador na plataforma. A Sioslife S.A. garante a
          confidencialidade de todos os dados inseridos pelo Utilizador na
          plataforma. Toda a interface foi criada de forma a facilitar a
          execução de todas as funcionalidades. A Sioslife S.A. garante que,
          dentro das limitações da aplicação, todas as funcionalidades irão
          funcionar como estipulado na página de ajuda do Software Sioslife®.
        </Typography>
      </div>
      <br />
      <br />
      <br />
      <Typography variant="h3" color="yellow" weight="medium">
        7. Responsabilidade da Empresa
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          A suspensão do serviços providenciado através das plataformas
          Sioslife® por razões não imputáveis à Sioslife S.A. não confere ao
          Utilizador o direito a ser indemnizado ou compensado, de qualquer
          forma, por qualquer dano, patrimonial ou outro, que para aquele
          advenha em virtude da referida suspensão. A Sioslife S.A. não
          responde, perante terceiros, por quaisquer danos ou prejuízos causados
          por atos do Utilizador que se traduzam na utilização abusiva da
          plataforma ou na violação dos termos e condições do presente Contrato
          de Licença de Utilização. Em caso de eliminação, pelo Utilizador, de
          qualquer informação ou dado previamente introduzido por si na
          plataforma, a Sioslife S.A. não tem obrigatoriedade de recuperar essa
          informação. A Sioslife S.A. não se responsabiliza pelo funcionamento
          com qualidade da aplicação noutros browsers e sistemas operativos que
          não aqueles especificados no ponto 4. A Sioslife S.A. não se
          responsabiliza pelo processamento de informação com qualidade quando
          por razões imputadas à velocidade da ligação de internet do
          Utilizador.
        </Typography>
      </div>
      <br />
      <br />
      <br />
      <Typography variant="h3" color="yellow" weight="medium">
        8. Obrigações do Utilizador
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          O acesso à plataforma realiza-se quando o Utilizador digita a sua
          identificação e a sua senha de acesso válida, ou através do Sistema de
          Login Simplificado construídos para utilizadores seniores, onde não há
          a necessidade de inserção de credenciais como o nome de utilizador e
          uma palavra passe. O Utilizador tem autorização e capacidade para
          modificar a senha a qualquer momento. O Utilizador tem a possibilidade
          de recuperar a palavra-chave. A Sioslife S.A. recomenda que o
          Utilizador mude a senha regularmente e que não divulgue os seus
          códigos de acesso a nenhuma pessoa que não esteja autorizada a
          praticar atos em seu nome. O Utilizador aceita toda a responsabilidade
          pela segurança de identificação do Utilizador e da respetiva senha.
          Incluem-se neste caso acesso não autorizado por terceiros, ainda que
          se trate de pessoa que aja por conta, em nome, em representação ou sob
          instruções, resultantes de vínculo laboral ou outro, do Utilizador.
          Toda a atividade de arquivo de dados e media no Software Sioslife® é
          da responsabilidade do Utilizador. O Utilizador concorda em não
          utilizar a plataforma para: Difamar, abusar, assediar, perseguir,
          ameaçar ou violar, sob qualquer outra forma, os direitos de outrem;
          Publicar, colocar um post, fazer upload, distribuir ou disseminar todo
          e qualquer tipo de material e/ou informação impróprio, difamatório,
          obsceno, desrespeitador ou ilegal; Efetuar uploads de ficheiros ou
          qualquer outro tipo de material que esteja protegido pelo Código do
          Direito de Autor ou que viole a legislação de protecção de dados
          pessoais/privacidade e quaisquer outros direitos de propriedade, com
          exceção de casos em que o Utilizador seja detentor ou tenha recebido
          as necessárias autorizações para o fazer; Efetuar uploads de ficheiros
          que contenham vírus, trojan horses, worms, time bombs, cancelboots,
          ficheiros corruptos ou qualquer outro tipo de ficheiros idênticos que
          sejam passíveis de danificar o computador, smartphone, tablet,
          dispositivo semelhante ou propriedade de outrem; Efetuar download
          ilegal, ou sujeito a qualquer outra proibição, de ficheiros
          pertencentes a terceiros ou aos demais utilizadores. Ao Utilizador não
          é permitido: Falsificar, omitir ou apagar qualquer atribuição autoral,
          legal ou qualquer outro tipo de etiquetas/designações de propriedade
          na origem/fonte do software/material contido num ficheiro uploaded;
          Manipular ou falsificar informação, com vista a ocultar a origem de
          produtos ou serviços; Gravar, recolher, reter ou coleccionar
          informação sobre outros utilizadores; Violar toda e qualquer
          lei/regulamentação aplicável, em especial as que regulamentam a
          protecção e o direito autoral, bem como corromper ou alterar
          procedimentos, políticas, requerimentos ou regulamentos ligados aos
          serviços; Criar uma identidade falsa com o objetivo de enganar outrem.
        </Typography>
      </div>
      <br />
      <br />
      <br />
      <Typography variant="h3" color="yellow" weight="medium">
        9. Licenciamento e Direiro de Autor
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          O Software Sioslife® não é vendido, mas licenciado ao Utilizador. Esta
          licença vigora a partir da data de aceitação do Contrato de Licença de
          Utilização e do respetivo pagamento integral. O Utilizador não poderá,
          salvo autorização expressa da Sioslife S.A. concedida para o efeito:
          Copiar qualquer parte do software; Distribuir, alugar, sublicenciar,
          transferir ou divulgar, por qualquer método, ou transmitir o Software
          Sioslife® a qualquer pessoa; Modificar, traduzir, fundir ou preparar
          trabalhos derivados do Software Sioslife®; Usar qualquer parte do
          software para fins não especificados e/ou autorizados pela Sioslife,
          Lda.
        </Typography>
      </div>
      <br />
      <br />
      <br />
      <Typography variant="h3" color="yellow" weight="medium">
        10. Alterações às Condições Gerais
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          A Sioslife S.A. poderá modificar estas Condições Gerais de Utilização
          futuramente. Qualquer alteração ou modificação contratual será
          comunicada ao Utilizador através de aviso online. O Utilizador
          concorda que o uso das soluções Sioslife®, após ter recebido aviso de
          alteração, emenda ou aditamento a este contrato, constituirá aceitação
          das referidas alterações, emendas ou aditamentos.
        </Typography>
      </div>
      <br />
      <br />
      <br />
      <Typography variant="h3" color="yellow" weight="medium">
        11. Privacidade e Direiro de Acesso aos Dados
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          Pelo presente contrato, o Utilizador concorda que a Sioslife S.A.
          possa recolher e usar informações técnicas que sejam fornecidas por si
          em relação aos serviços de suporte relacionados com o Software
          Sioslife®. Os dados pessoais recolhidos pela Sioslife S.A. constam de
          uma base de dados protegida, sendo confidenciais e utilizados
          exclusivamente para cumprimento das obrigações legais da Sioslife,
          Lda. e automaticamente processados de acordo com a Lei n.º 58/2019, de
          08 de agosto e com o Regulamento (UE) 2016/679 do Parlamento e do
          Conselho, de 27 de abril de 2016. Nos termos da legislação aplicável,
          a Sioslife S.A. obriga-se a conferir ao Utilizador cujo processo é
          recolhido, o direito de aceder, de se opor, de retificar e de eliminar
          os respetivos dados, conforme estipulado na Política de Privacidade e
          Proteção de Dados, disponível em{" "}
          <a href={launcher ? "#" : "https://www.sioslife.com"}>
            https://www.sioslife.com
          </a>
          .
        </Typography>
      </div>
    </LegalDocument>
  );
}

TermsAndConditions.propTypes = {
  launcher: PropTypes.bool,
};

TermsAndConditions.defaultProps = {
  launcher: false,
};

export default TermsAndConditions;
